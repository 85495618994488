// extracted by mini-css-extract-plugin
export var bannerImageContainer = "BrowseCategoryContent-module--bannerImageContainer--d90f6";
export var bannerImageDescription = "BrowseCategoryContent-module--bannerImageDescription--a1354";
export var bannerImageShade = "BrowseCategoryContent-module--bannerImageShade--cf344";
export var bannerImageTitle = "BrowseCategoryContent-module--bannerImageTitle--a3272";
export var browseCategoriesMobile = "BrowseCategoryContent-module--browseCategoriesMobile--91746";
export var coureseSerachResultContent = "BrowseCategoryContent-module--coureseSerachResultContent--11b7a";
export var coureseSerachResultContentBottom = "BrowseCategoryContent-module--coureseSerachResultContentBottom--32c0e";
export var customClass = "BrowseCategoryContent-module--customClass--32318";
export var customTextClass = "BrowseCategoryContent-module--customTextClass--6081d";
export var gap = "BrowseCategoryContent-module--gap--3611a";
export var lmsScrollbar = "BrowseCategoryContent-module--lms-scrollbar--ec978";