import React from "react";
import { Col } from "antd";

import * as classes from "../../../styles/browse.module.scss";
import { useBrowseCoursesList } from "../../../components/Browse/hooks/useBrowseCoursesList";
import LayoutWithOutPadding from "../../../components/LayoutWithOutPadding";
import CoursesCategories from "../../../components/Browse/CoursesCategories";
import BrowseCategoryContent from "../../../components/Browse/BrowseCategoryContent";

const Category = (props) => {
  const { pageContext } = props;
  const { category } = pageContext;
  const { categoryCourses } = useBrowseCoursesList();

  return (
    <LayoutWithOutPadding>
      <Col className={classes.browsePageContainer}>
        <Col className={classes.browseCategories}>
          <CoursesCategories
            categoryList={categoryCourses?.data}
            activeSlug={category?.slug}
          />
        </Col>
        <Col className={classes.browseCourses}>
          <BrowseCategoryContent
            title={category?.name}
            courses={category?.courses?.nodes}
            categoryList={categoryCourses?.data}
            activeSlug={category?.slug}
          />
        </Col>
      </Col>
    </LayoutWithOutPadding>
  );
};

export default Category;
